.text-sidebar-item {
    color: #FE8438;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.chosen-sidebar {
    background-color: #FFF7ED;
    -webkit-text-fill-color: #FE8438;
}


.inactive-sidebar {
    background-color: #FD853A;
    -webkit-text-fill-color: white;
}

.separator {
    padding-top: 20px;
    margin-top: 20px;
}

.submenu-text {
    -webkit-text-fill-color: white;
}

.notification-count {
    background-color: white;
    border-radius: 70% 70% 70% 70%;
    -webkit-text-fill-color: #FE8438;
}

.bottom-container-sidebar {
    background-color: #EC4A0A;
    width: 220px;
    height: 56px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-left: 5px;
}


.bottom-initial-container-sidebar {
    background: linear-gradient(115.45deg, #4C95F8 6.6%, #196DDD 68.57%, #2F80ED 89.64%);
    border-radius: 50px;
    width: 32px;
    height: 32px;
    margin-right: 7px;
    padding-left: 10px;
    padding-top: 3px;
}

.name-text-sidebar {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.title-text-sidebar {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}