.inactive-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #98A2B3
}

.active-text {
    color: #667085;
}