.default-size {
    min-width: 271px;
    width: 19vw;
    height: 154px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #1018281A;
    padding-left: 1vw;
}

.text-monitor {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}

.text-data {
    color: white;
    font-family: Inter;
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: left;
}