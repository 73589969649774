.searchbar-title-einvoice-container {
    background-color: #EAEEF6;
    border-radius: 5px;
    margin-top: 1px;
    margin-left: 1px;
}

.suggestion-input {
    border-radius: 5px;
    background-color: #EAEEF6;
}

.chosen-tag {
    border-radius: 14px;
    background-color: #EAEFF5;
    width: max-content;
    height: 28px;
    margin-right: 5px;
    padding-top: 5px;
    padding-inline: 10px;
}

.chosen-tag-name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #828282;
}

.button-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
    padding: 5px;
    padding-top: 10px;
    margin-left: 10px;
    border-radius: 8px;
}

.active-submenu-item {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.searchbar-add-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #EC4A0A;
    margin-right: 20px;
}

.filter-text-menu {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #EC4A0A;
}

.filter-text-count {
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 10px;
    color: white;
    background-size: 16px 16px;
    background-color: #EC4A0A;
    padding: 1px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding-bottom: 3px;
}

.source-item-container {
    height: 28px;
    padding: 5px 12px 6px 12px;
    border-radius: 8px;
    border: 1px;
    gap: 8px;
    background-color: #EAECF0;
}

.source-item-non-chosen-container {
    border-style: solid;
    border-color: #98A2B3;
}

.source-item-active-container {
    background-color: #FFEAD5;
    border-color: #EC4A0A;
    border-style: solid;
}

.filter-button-container {
    border: 1px solid #D0D5DD;
    width:  134px; 
    height: 34px;
    text-align: center;
    padding-top: 4px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    border-radius: 4px;
    color: #344054;
    margin-right: 10px;
}

.apply-button-container {
    border: 1px solid #EC4A0A;
    color: white;
    background-color: #EC4A0A;
}

.orange-text {
    color: #EC4A0A;
}

.reset-all-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.disabled-pointer {
    pointer-events: none;
    color: #D0D5DD;
}