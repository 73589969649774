@media (min-width: 650px) {
    .main-content-container {
        margin-left: 280px;
    }
}

.button-tab-container {
    width: 245px;
    height: 42px;
    border-radius: 5px;
    background-color: #EAEFF5;
}


.main-title {
    font-family: Inter;
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
}

.inactive-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #98A2B3;
}

.active-container {
    background-color: white;
    border-radius: 5px;
    margin: -8px -12px;
}

.active-text {
    color: #475467;
    margin: 8px 12px;
}

.inbox-container {
    box-shadow: 0px 5px 20px 10px #5977C21A;
    border-radius: 10px;
}

.inbox-title-text {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 12px;
}

.inbox-notif-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #EC4A0A;
    background-color: #FFEAD5;
    width: 52px;
    height: 24px;
    padding: 2px 8px 4px 8px;
    border-radius: 8px;
    gap: 10px;
}

.all-message-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #EC4A0A;
    margin-top: 5px;
}

.first-inbox-container {
    border-bottom: 0.5px solid #D0D5DD;
    padding: 0;
    margin-top: 10px;
}