.title-graph {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
}

.box-container {
    box-shadow: 0px 5px 20px 10px #5977C21A;
    padding-top: 20px;
    border-radius: 10px;
}