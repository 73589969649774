.outer-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.inner-container {
    position: absolute;
    width: 540px;
    height: 440px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.23);
    border-radius: 50px;
}

.row-container {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50px;
}

.first-row {
    padding-top: 8%;
    padding-left: 25%;
    padding-right: 10%;
}

.welcome-text {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 30px
}

.sign-in-text {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
}

.btn-signin {
    background-color: white;
    width: 80%;
    height: 48px;
    opacity: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    border-radius: 8px;
    border: 1px rgba(208, 213, 221, 1);
    margin-top: 40px;
    cursor: pointer;
}

.btn-logo {
    margin-block: 10px;
    margin-right: 10px;
}

.btn-text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(16, 24, 40, 1);
    margin-block: 10px;
}