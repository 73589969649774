@media (min-width: 650px) {
    .main-content-container {
        margin-left: 280px;
    }
}

.einvoice-menu-button {
    padding: 7px, 16px, 7px, 20px;
    border-radius: 4px;
    background-color: #FB6514;
    width: 176px;
}

.einvoice-menu-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.einvoice-submenu-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.einvoice-title-graph-text {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
    padding-block: 10px;
    padding-inline: 20px;
}

.einvoice-title-graph-container {
    border-bottom: 0.5px solid #D0D5DD;
    padding: 0;
}