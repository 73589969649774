.searchbar-title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    /* line-height: 17px; */
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
    margin-left: 5px;
    margin-top: 10px;
}

.searchbar-title-container {
    background-color: #EAEEF6;
    border-radius: 5px;
}

.left-icon {
    margin-left: -30px;
    margin-top: 10px;
}

.suggestion-input {
    border-radius: 5px;
    background-color: #EAEEF6;
    /* width: 40vw; */
}

.chosen-tag {
    border-radius: 14px;
    background-color: #EAEFF5;
    width: max-content;
    height: 28px;
    margin-right: 5px;
    padding-top: 5px;
    padding-inline: 10px;
}

.chosen-tag-name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #828282;
}