.legend-item-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5px;
}

.legend-item-count-text {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
}

.legend-symbol {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    /* margin-top: 10px; */
}