.noaccess-container {
    width: 100%;
    height: 100%;
}

.noaccess-detail-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #98A2B3;
}