.progress-ring__circle {
    stroke-dasharray: 400, 400;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.done-text {
    font-family: Inter;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #FB6514;
}

.total-task-text {
    color: #667085;
}

.task-completed-text {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #101828;
}