.container-outer {
    background-color: rgb(244,245,249);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    width: 100vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.failed-container {
    display: flex;
    width: auto;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-text {
    font-family: Inter;
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
}

.detail-text {
    margin-top: 10px;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.btn-container-failed {
    background-color: rgba(236, 74, 10, 1);
    width: 178px;
    height: 40px;
    border-radius: 4px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-top: 30px;
    cursor: pointer;
}

.btn-failed-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    padding-top: 10px;
}